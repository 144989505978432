.login-wrapper {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../assets/img/blog/17.jpg');
    color: rgb(252, 252, 252);
    border: 1px solid;
}


.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
.cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(117, 54, 230, .1);
}

.login-content {
    max-width: 400px;
    margin: 100px auto 50px;
}

.auth-head-icon {
    position: relative;
    height: 60px;
    width: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    background-color: #fff;
    color: #5c6bc0;
    box-shadow: 0 5px 20px #d6dee4;
    border-radius: 50%;
    transform: translateY(-50%);
    z-index: 2;
}