.page-wrapper {
    background-color: #f8f9fa;
    /* Light background for better contrast */
}

.ibox {
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.card-header {
    border-radius: 10px 10px 0 0;
    /* Rounded corners for the header */
}

.img-thumbnail {
    max-height: 100px;
    border-radius: 10px;
    border: 2px solid #007bff;
    /* Blue border to match theme */
}

.btn {
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #0056b3;
    /* Darker shade on hover */
}

.d-flex.flex-wrap {
    display: flex;
    flex-wrap: nowrap;
    /* Prevent wrapping */
    gap: 10px;
    /* Adjust spacing between checkboxes */
    align-items: center;
    /* Align vertically */
}

.card {
    margin: 0;
    /* Remove card-specific margins for tighter spacing */
    padding: 5px;
}