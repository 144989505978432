.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  /* Adjust z-index to ensure it stays on top */
  background-color: #fff;
  /* Set background color */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  /* Optional: Add a shadow for better visibility */
}

* {
  margin: 0;
  padding: 0;
  font-size: 16px;
  box-sizing: border-box;
  font-family: 'Karla', sans-serif;
  /* Corrected property name and syntax */
}