body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4cb;
}

.invoice-container {
  max-width: 800px;
  margin: 20px auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header {
  text-align: center;
  /* Centers all items in the header */
  margin-bottom: 20px;
}

.header img {
  display: block;
  /* Ensures logo is on its own line */
  margin: 0 auto;
  /* Centers the logo */
  max-width: 150px;
}

.header h1 {
  margin: 10px 0;
  /* Adds spacing around the title */
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.header p {
  margin: 10px 0;
  /* Adds spacing around the address */
  font-size: 14px;
  color: #666;
}

.invoice-details,
.customer-details,
.invoice-table {
  margin-bottom: 20px;
}

.customer-details,
.invoice-details {
  display: flex;
  justify-content: space-between;
}

.customer-details div,
.invoice-details div {
  width: 48%;
}

.invoice-table table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-table th,
.invoice-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.invoice-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.total {
  text-align: right;
  font-size: 15px;
  margin-top: 20px;
}

.footer {
  text-align: center;
  margin-top: 20px;
  color: #999;
  font-size: 14px;
}

.sold-by {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.qr-code {
  margin-left: 20px;
}

.actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.actions button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}