.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-info {
    font-size: 16px;
    margin: 0 10px;
}
