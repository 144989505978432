.sidebar {
  width: 230px;
  height: 100vh;
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 20px;
  position: fixed;
  top: 0; /* Corrected value */
  left: 0;
  overflow-y: auto;
}

.sidebar-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
}

.sidebar-item {
  margin: 10px 0;
}

.sidebar-link {
  color: #ecf0f1;
  text-decoration: none;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.sidebar-icon {
  margin-right: 10px;
}

.dropdown-menu {
  list-style: none;
  padding: 0;
  margin-top: 10px;
  padding-left: 20px;
  background-color: #34495e;
}

.dropdown-item {
  margin: 5px 0;
}

.dropdown-link {
  color: #ecf0f1;
  text-decoration: none;
  font-size: 16px;
  display: block;
}

.dropdown-link:hover {
  background-color: #2c3e50;
  padding: 5px;
  border-radius: 5px;
}

.dropdown-icon {
  margin-left: auto;
  font-size: 12px;
}
